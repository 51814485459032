import React, { Fragment, useState, useRef } from 'react'
import Header from '../Header'
import Footer from '../Footer'
// import emailjs from '@emailjs/browser';
import axios from 'axios';
import Swal from 'sweetalert2';

const Contact = () => {
    const [formData, setFormData] = useState({})
    const form = useRef();
    const handleSubmit =async  (e) => {
        e.preventDefault();
        const data={
            service_id:'service_b2zr38f',
            template_id:'template_iull0f6',
            user_id:'0ggPDYdyYK07UOoiv',
            template_params:{
                from_name:formData.user_name,
                form_email:formData.user_email,
                to_name:"lawyeromnagar.com",
                message:formData.message
            }
        }
        try{
            const res= await axios.post("https://api.emailjs.com/api/v1.0/email/send", data);
            console.log(res.data);
            if (res.data == "OK") {
                setFormData({
                    user_name:"",
                    user_email:"",
                    message:""
                });
                Swal.fire({
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500
                  });
            }
        }catch(err){
            console.log(err);
        }

    }

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }
    return (
        <Fragment>
            <Header />
            <div>
                <div className="container-fluid py-5">
                    <div className="container py-5">
                        <div className="text-center pb-2">
                            <h6 className="text-uppercase">Contact Us</h6>
                            <h1 className="mb-4">Contact For Any Query</h1>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 mb-5 mb-lg-0">
                                <div className="contact-form">
                                    <div id="success"></div>


                                    <form name="sentMessage" id="contactForm" novalidate="novalidate" ref={form} onSubmit={handleSubmit}>
                                        <div className="form-row">
                                            <div className="col-sm-6 control-group">
                                                <input type="text" className="form-control p-4" id="name" placeholder="Your Name" required="required" data-validation-required-message="Please enter your name"
                                                    name='user_name'
                                                    value={formData.user_name}
                                                    onChange={handleChange}
                                                />
                                                <p className="help-block text-danger"></p>
                                            </div>
                                            <div className="col-sm-6 control-group">
                                                <input type="email" className="form-control p-4" id="email" placeholder="Your Email" required="required" data-validation-required-message="Please enter your email"
                                                    name='user_email'
                                                    value={formData.user_email}
                                                    onChange={handleChange}
                                                />
                                                <p className="help-block text-danger"></p>
                                            </div>
                                        </div>
                                        <div className="control-group">
                                            <textarea className="form-control p-4" rows="6" id="message" placeholder="Message" required="required" data-validation-required-message="Please enter your message"
                                                name='message'
                                                value={formData.message}
                                                onChange={handleChange}
                                            ></textarea>
                                            <p className="help-block text-danger"></p>
                                        </div>
                                        <div>
                                            <button className="btn btn-primary btn-block" type="submit" id="sendMessageButton">Send Message</button>
                                        </div>
                                    </form>


                                </div>
                            </div>
                            <div className="col-lg-6" style={{ minHeight: '400px' }}>
                                <div className="position-relative h-100 rounded overflow-hidden">
                                    <iframe style={{ width: '100%', height: '100%', objectFit: 'cover', border: '0' }}
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3001156.4288297426!2d-78.01371936852176!3d42.72876761954724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4ccc4bf0f123a5a9%3A0xddcfc6c1de189567!2sNew%20York%2C%20USA!5e0!3m2!1sen!2sbd!4v1603794290143!5m2!1sen!2sbd"
                                        frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </Fragment>
    )
}

export default Contact
