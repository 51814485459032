
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './component/Home';
import { Routes, Route } from 'react-router-dom';
import "../src/assets/css/style.css"
import "../src/assets/css/style.min.css"
import Contact from './component/HeaderPages/Contact';
import About from './component/HeaderPages/About';
import Service from './component/HeaderPages/Service';


function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/about" element={<About/>} />
      <Route path="/service" element={<Service/>} />
    </Routes>
  );
}

export default App;
