import React, { Fragment } from 'react'
import Header from '../Header'
import Footer from '../Footer'
import aboutImg from "../../assets/img/about.jpg"

const About = () => {
  return (
  <Fragment>
    <Header/>
    <div className="container-fluid py-5">
        <div className="container py-5">
            <div className="row">
                <div className="col-lg-5">
                    <img className="img-fluid rounded" src={aboutImg} alt="" />
                </div>
                <div className="col-lg-7 mt-4 mt-lg-0">
                    <h2 className="position-relative text-center bg-white text-primary rounded p-3 mt-4 mb-4 d-none d-lg-block" style={{width: '350px', marginLeft: '-205px'}}>15 Years Experience</h2>
                    <h6 className="text-uppercase">ABOUT US</h6>
                    <p className="mb-4">Our seasoned criminal defense team delivers unwavering support and expert representation to individuals facing charges. With 15 years of experience, we passionately uphold the principle of innocence until proven guilty. Our personalized approach involves attentive listening, thorough investigation, and strategic defense tailored to each client. From misdemeanors to serious felonies, we tirelessly advocate for your rights and future. Integrity, professionalism, and a relentless pursuit of justice define our ethos. Trust us to navigate the complexities of the legal system, negotiate plea bargains, and represent you in court. Contact us for a confidential consultation and let us safeguard your freedom.</p>
                    <a href="" className="btn btn-primary mt-2">Learn More</a>
                </div>
            </div>
        </div>
    </div>
    <Footer/>

  </Fragment>
  )
}

export default About;
