import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {

    return (
        <div>
            {/* <!-- Footer Start --> */}
            <div className="container-fluid bg-secondary text-white pt-5 px-sm-3 px-md-5" style={{ marginTop: '90px' }}>
                <div className="row mt-5">
                    <div className="col-lg-4">
                        <div className="d-flex justify-content-lg-center p-4" style={{ background: 'rgba(256, 256, 256, 0.05)' }}>
                            <i className="fa fa-2x fa-map-marker-alt text-primary"></i>
                            <div className="ml-3">
                                <h5 className="text-white">Our Office</h5>
                                <p className="m-0">Chamber No 487,Gali No 10,District & Session</p>
                                 <p className="m-0">Court Surajpur,Greater Noida,Gautambudh Nagar</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="d-flex justify-content-lg-center p-4" style={{ background: 'rgba(255, 255, 255, 0.05)' }}>
                            <i className="fa fa-2x fa-envelope-open text-primary"></i>
                            <div className="ml-3">
                                <h5 className="text-white">Email Us</h5>
                                <p className="m-0">info@example.com</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4">
                        <div className="d-flex justify-content-lg-center p-4" style={{ background: 'rgba(255, 255, 255, 0.05)' }}>
                            <i className="fa fa-2x fa-phone-alt text-primary"></i>
                            <div className="ml-3">
                                <a href="https://wa.me/+919312126754" className="text-white" style={{textDecoration: 'none'}} target="_blank"><h5 className="text-white">Call Us</h5>
                                <p className="m-0">+919312126754</p></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row pt-5">
                    <div className="col-lg-6 col-md-6 mb-5">
                        <a href="index.html" className="navbar-brand">
                            <h1 className="m-0 mt-n2 display-4 text-primary text-uppercase">Om Nagar</h1>
                        </a>
                        <p>Best Criminal & Civil Lawyer in Surajpur Greater Noida</p>
                        <div className="d-flex justify-content-start mt-4">
                            <a className="btn btn-lg btn-outline-light btn-lg-square mr-2" href="#"><i className="fab fa-twitter"></i></a>
                            <a className="btn btn-lg btn-outline-light btn-lg-square mr-2" href="#"><i className="fab fa-facebook-f"></i></a>
                            <a className="btn btn-lg btn-outline-light btn-lg-square mr-2" href="#"><i className="fab fa-linkedin-in"></i></a>
                            <a className="btn btn-lg btn-outline-light btn-lg-square" href="#"><i className="fab fa-instagram"></i></a>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-5">
                        <h4 className="font-weight-semi-bold text-primary mb-4">Popular Links</h4>
                        <div className="d-flex flex-column justify-content-start">
                            <Link className="text-white mb-2" to="/"><i className="fa fa-angle-right mr-2"></i>Home</Link>
                            <Link className="text-white mb-2" to="/about"><i className="fa fa-angle-right mr-2"></i>About</Link>
                           
                   
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-5">
                        <h4 className="font-weight-semi-bold text-primary mb-4">Quick Links</h4>
                        <div className="d-flex flex-column justify-content-start">
                            <Link className="text-white mb-2" to="/service"><i className="fa fa-angle-right mr-2"></i>Services</Link>
                            <Link className="text-white" to="/contact"><i className="fa fa-angle-right mr-2 mt-1"></i>Contact</Link>
                        </div>
                    </div>
                </div>
                <div className="row p-4 mt-5 mx-0" style={{ background: 'rgba(255, 255, 255, 0.05)' }}>
                    <div className="col-md-6 text-center text-md-left mb-3 mb-md-0">
                        <p className="m-0 text-white">&copy; 2024 <a className="font-weight-bold" href="https://lawyeromnagar.com">lawyeromnagar.com</a>. All Rights Reserved.</p>
                    </div>
                    <div className="col-md-6 text-center text-md-right">
                        <p className="m-0 text-white">Designed by <a className="font-weight-bold" href="https://l4rg.com">L4rg</a></p>
                    </div>
                </div>
            </div>
            {/* <!-- Footer End --> */}
        </div>
    )
}

export default Footer
