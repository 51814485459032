import React,{useRef, useState} from 'react'
import caursoel1 from "../assets/img/carousel-1.jpg"
import caursoel2 from "../assets/img/carousel-2.jpg"
import about from "../assets/img/about.jpg"
import feature from "../assets/img/feature.jpg"
import testimonial from "../assets/img/testimonial-1.jpg"
import testimonial2 from "../assets/img/testimonial-2.jpg"
import Footer from './Footer'
import Header from './Header'
import Im1 from "../assets/gallery/im1.jpeg"
import Im2 from "../assets/gallery/im2.jpeg"
import Im3 from "../assets/gallery/im3.jpeg"
import Im4 from "../assets/gallery/im4.jpeg"
import Im5 from "../assets/gallery/im5.jpeg"
import Im6 from "../assets/gallery/im6.jpeg"
import Im7 from "../assets/gallery/im7.jpeg"
import Im8 from "../assets/gallery/im8.jpeg"
import Im9 from "../assets/gallery/im9.jpeg"
import Im10 from "../assets/gallery/im10.jpeg"
import Im11 from "../assets/gallery/im11.jpeg"
import Im12 from "../assets/gallery/im12.jpeg"
import Im13 from "../assets/gallery/im13.jpeg"
import Im14 from "../assets/gallery/im14.jpeg"
import { Container} from 'react-bootstrap'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import axios from 'axios'
import Swal from 'sweetalert2'




const Home = () => {
    const galleryImg = [
        {
            image: Im1
        },
        {
            image: Im2
        },
        {
            image: Im3
        },
        {
            image: Im4
        },
        {
            image: Im5
        },
        {
            image: Im6
        },
        {
            image: Im7
        },
        {
            image: Im8
        },
        {
            image: Im9
        },
        {
            image: Im10
        },
        {
            image: Im11
        },
        {
            image: Im12
        },
        {
            image: Im12
        },
        {
            image: Im13
        },
        {
            image: Im14
        }
    ]

    const settings = {
        dots: false, // Remove dots
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        className: 'center',
        centerMode: true,
        centerPadding: '60px',
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              centerPadding: '40px',
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              centerPadding: '20px',
            },
          },
        ],
      };

      const [formData, setFormData] = useState({})
      const form = useRef();
      const handleSubmit =async  (e) => {
          e.preventDefault();
          const data={
               service_id:'service_b2zr38f',
				template_id:'template_iull0f6',
				user_id:'0ggPDYdyYK07UOoiv',
              template_params:{
                  from_name:formData.user_name,
                  form_email:formData.user_email,
                  to_name:"lawyeromnagar.com",
                  message:formData.message
              }
          }
          try{
              const res= await axios.post("https://api.emailjs.com/api/v1.0/email/send", data);
              console.log(res.data);
              if (res.data == "OK") {
                  setFormData({
                      user_name:"",
                      user_email:"",
                      message:""
                  });
                  Swal.fire({
                    icon: "success",
                    showConfirmButton: false,
                    timer: 1500
                  });
              }
          }catch(err){
              console.log(err);
          }
  
      }
  
      const handleChange = (e) => {
          setFormData({ ...formData, [e.target.name]: e.target.value })
      }
    return (
        <div>
            <Header />
            {/* <!-- Header End --> */}
            {/* <!-- Carousel Start --> */}
            <div className="container-fluid p-0 mb-5 pb-5">
                <div id="header-carousel" className="carousel slide carousel-fade" data-ride="carousel">
                    <div className="carousel-inner">
                        <div className="carousel-item position-relative active" style={{ height: '100vh', minHeight: '400px' }}>
                            <img className="position-absolute w-100 h-100" src={caursoel1} style={{ objectFit: 'cover' }} />
                            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                <div className="p-3" style={{ maxWidth: '900px' }}>
                                    <h4 className="text-white text-uppercase mb-4" style={{ letterSpacing: '3px' }}>My Name Is Om Nagar
                                        & I’m An Expert
                                        In Criminal Law</h4>
                                    <h3 className="display-2 text-capitalize text-white mb-4">My fighting Is for your justice</h3>
                                    <a className="btn btn-primary py-3 px-5 mt-2" href="https://wa.me/9312126754" target="_blank">Get An Appointment</a>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item position-relative" style={{ height: '100vh', minHeight: '400px' }}>
                            <img className="position-absolute w-100 h-100" src={caursoel2} style={{ objectFit: 'cover' }} />
                            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                <div className="p-3" style={{ maxWidth: '900px' }}>
                                    <h4 className="text-white text-uppercase mb-4" style={{ letterSpacing: '3px' }}>Results You Deserve</h4>
                                    <h3 className="display-2 text-capitalize text-white mb-4">We prepared to oppose for you</h3>
                                    <a className="btn btn-primary py-3 px-5 mt-2" href="#">Call Us Now</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <a className="carousel-control-prev" href="#header-carousel" data-slide="prev">
                        <div className="btn btn-lg btn-secondary btn-lg-square">
                            <span className="carousel-control-prev-icon"></span>
                        </div>
                    </a>
                    <a className="carousel-control-next" href="#header-carousel" data-slide="next">
                        <div className="btn btn-lg btn-secondary btn-lg-square">
                            <span className="carousel-control-next-icon"></span>
                        </div>
                    </a> */}
                </div>
            </div>
            {/* <!-- Carousel End --> */}


            {/* <!-- About Start --> */}
            <div className="container-fluid py-5">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-lg-5">
                            <img className="img-fluid rounded" src={about} alt="" />
                        </div>
                        <div className="col-lg-7 mt-4 mt-lg-0">
                            <h2 className="position-relative text-center bg-white text-primary rounded p-3 mt-4 mb-4 d-none d-lg-block" style={{ width: '350px', marginLeft: '-205px' }}>15 Years Experience</h2>
                            <h3 className="text-uppercase"> About Us</h3>
                            <p>Our seasoned criminal defense team delivers unwavering support and expert representation to individuals facing charges. With 15 years of experience, we passionately uphold the principle of innocence until proven guilty. Our personalized approach involves attentive listening, thorough investigation, and strategic defense tailored to each client. From misdemeanors to serious felonies, we tirelessly advocate for your rights and future. Integrity, professionalism, and a relentless pursuit of justice define our ethos. Trust us to navigate the complexities of the legal system, negotiate plea bargains, and represent you in court. Contact us for a confidential consultation and let us safeguard your freedom..</p>
                            <a href="/about" className="btn btn-primary mt-2">Learn More</a>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- About End --> */}


            {/* <!-- Services Start --> */}
            <div className="container-fluid py-5">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-lg-3">
                            <h6 className="text-uppercase">Practice Areas</h6>
                            <h1 className="mb-4">Our Practice Areas</h1>
                            <p>We specialize in a comprehensive range of practice areas to meet the diverse legal needs of our clients. From criminal defense and civil litigation to family law and business matters, we provide adept legal counsel and representation. In criminal cases, we diligently defend clients rights, ensuring fair treatment and striving for favorable outcomes.</p>
                            <a href="/service" className="btn btn-primary mt-2">More Services</a>
                        </div>
                        <div className="col-lg-9 pt-5 pt-lg-0">
                            <div className="bg-primary rounded" style={{ height: '200px' }}></div>
                            <div className="owl-carousel service-carousel position-relative" style={{ marginTop: '-100px', padding: '0 30px' }}>
                                <div className="d-flex flex-column align-items-center text-center bg-white rounded pt-4">
                                    <div className="icon-box bg-secondary text-primary mt-2 mb-4">
                                        <i className="fa fa-2x fa-gavel"></i>
                                    </div>
                                    <h5 className="mb-4 px-4">Criminal Law</h5>
                                    <p className="m-0">Expert defense against charges, ensuring fair trials and protecting clients rights and freedoms with diligence</p>
                                </div>
                                <div className="d-flex flex-column align-items-center text-center bg-white rounded pt-4">
                                    <div className="icon-box bg-secondary text-primary mt-2 mb-4">
                                        <i className="fa fa-2x fa-landmark"></i>
                                    </div>
                                    <h5 className="mb-4 px-4">Civil Law</h5>
                                    <p className="m-0">Resolving disputes and upholding rights through legal avenues, fostering justice and harmony in society.</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Services End --> */}


            {/* <!-- Appointment Start --> */}
            <div className="container-fluid py-5">
                <div className="container py-5">
                    <div className="bg-appointment rounded">
                        <div className="row h-100 align-items-center justify-content-center">
                            <div className="col-lg-6 py-5">
                                <div className="rounded p-5 my-5" style={{ background: 'rgba(55, 55, 63, 0.7)' }}>
                                    <h1 className="text-center text-white mb-4">Get An Appointment</h1>
{/* testing */}

                                    <form ref={form} onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <input type="text" className="form-control border-0 p-4" placeholder="Your Name" required="required" 
                                                 name='user_name'
                                                 value={formData.user_name}
                                                 onChange={handleChange}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <input type="email" className="form-control border-0 p-4" placeholder="Your Email" required="required" 
                                            
                                            name='user_email'
                                            value={formData.user_email}
                                            onChange={handleChange}
                                            />
                                        </div>
                                        {/* <div className="form-row">
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <div className="date" id="date" data-target-input="nearest">
                                                        <input type="text" className="form-control border-0 p-4 datetimepicker-input" placeholder="Select Date" data-target="#date" data-toggle="datetimepicker" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="form-group">
                                                    <div className="time" id="time" data-target-input="nearest">
                                                        <input type="text" className="form-control border-0 p-4 datetimepicker-input" placeholder="Select Time" data-target="#time" data-toggle="datetimepicker" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}

                                        <div className="form-group">
                                            {/* <select className="custom-select border-0 px-4" style={{ height: '47px' }}>
                                                <option selected>Select A Service</option>
                                                <option value="1">Service 1</option>
                                                <option value="2">Service 1</option>
                                                <option value="3">Service 1</option>
                                            </select> */}

<textarea className="form-control p-4" rows="6" id="message" placeholder="Message" required="required" data-validation-required-message="Please enter your message"
                                                name='message'
                                                value={formData.message}
                                                onChange={handleChange}
                                            ></textarea>
                                        </div>
                                        <div>
                                            <button className="btn btn-primary btn-block border-0 py-3" type="submit">Get An Appointment</button>
                                        </div>
                                    </form>


                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Appointment End --> */}


            {/* <!-- Features Start --> */}
            <div className="container-fluid py-5">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-lg-6" style={{ minHeight: '500px' }}>
                            <div className="position-relative h-100 rounded overflow-hidden">
                                <img className="position-absolute w-100 h-100" src={feature} style={{ objectFit: 'cover' }} />
                            </div>
                        </div>
                        <div className="col-lg-6 pt-5 pb-lg-5">
                            <div className="feature-text bg-white rounded p-lg-5">
                                <h6 className="text-uppercase">Our Features</h6>
                                <h1 className="mb-4">Why Choose Us</h1>
                                <div className="d-flex mb-4">
                                    <div className="btn-primary btn-lg-square px-3" style={{ borderRadius: '50px' }}>
                                        <h5 className="text-secondary m-0">01</h5>
                                    </div>
                                    <div className="ml-4">
                                        <h5>Best Law Practices</h5>
                                        <p className="m-0"> Unparalleled expertise and dedication ensure top-notch legal strategies tailored to your case's unique needs.</p>
                                    </div>
                                </div>
                                <div className="d-flex mb-4">
                                    <div className="btn-primary btn-lg-square px-3" style={{ borderRadius: '50px' }}>
                                        <h5 className="text-secondary m-0">02</h5>
                                    </div>
                                    <div className="ml-4">
                                        <h5>Efficiency & Trust</h5>
                                        <p className="m-0"> Our efficient processes coupled with unwavering trust build a solid foundation for your legal journey.</p>
                                    </div>
                                </div>
                                <div className="d-flex">
                                    <div className="btn-primary btn-lg-square px-3" style={{ borderRadius: '50px' }}>
                                        <h5 className="text-secondary m-0">03</h5>
                                    </div>
                                    <div className="ml-4">
                                        <h5>Results You Deserve</h5>
                                        <p className="m-0">With relentless advocacy and proven track record, we deliver the outcomes you rightfully deserve.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Features End --> */}


            {/* <!-- Action Start --> */}
            <div className="container-fluid py-5">
                <div className="container py-5">
                    <div className="bg-action rounded" style={{ height: '500px' }}>
                        <div className="row h-100 align-items-center justify-content-center">
                            <div className="col-lg-7 text-center">
                                <h1 className="text-white mb-4">Let's get started. Call us now for a Free Consultation</h1>
                                <a className="btn btn-primary py-3 px-5 mt-2" href="https://wa.me/9312126754" target="_blank">Call Us Now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Action End --> */}


            {/* <!-- Testimonial Start --> */}
            <div className="container-fluid py-5">
                <div className="container py-5">
                    <div className="text-center pb-5">
                        <h6 className="text-uppercase">Testimonial</h6>
                        <h1 className="mb-5">What Our Clients Say</h1>
                    </div>
                    <div className="owl-carousel testimonial-carousel">
                        <div className="testimonial-item">
                            <div className="testimonial-text position-relative bg-secondary text-light rounded p-5 mb-4">
                                Sed ea amet kasd elitr stet nonumy, stet rebum et ipsum est duo elitr clita lorem
                            </div>
                            <div className="d-flex align-items-center pt-3">
                                <img className="img-fluid rounded-circle" src={testimonial} style={{ width: '80px', height: '80px' }} alt="" />
                                <div className="pl-4">
                                    <h5>Client Name</h5>
                                    <p className="m-0">Profession</p>
                                </div>
                            </div>
                        </div>
                        <div className="testimonial-item">
                            <div className="testimonial-text position-relative bg-secondary text-light rounded p-5 mb-4">
                                Sed ea amet kasd elitr stet nonumy, stet rebum et ipsum est duo elitr clita lorem
                            </div>
                            <div className="d-flex align-items-center pt-3">
                                <img className="img-fluid rounded-circle" src={testimonial2} style={{ width: '80px', height: '80px' }} alt="" />
                                <div className="pl-4">
                                    <h5>Client Name</h5>
                                    <p className="m-0">Profession</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Container>
                <Slider {...settings} >
                    {galleryImg.map((val, index) => (
                        <div key={index} className="gallery-item">
                            <div className="card">
                                <img src={val.image} className="card-img-top" alt={`Image ${index + 1}`} />
                            </div>
                        </div>
                    ))}
                </Slider>
            </Container>


            {/* <!-- Testimonial End --> */}
            <Footer />
            {/* <!-- Back to Top --> */}
            <a href="#" className="btn btn-primary px-3 back-to-top"><i className="fa fa-angle-double-up"></i></a>

        </div>
    )
}

export default Home;
