import React from 'react'
import { Link } from 'react-router-dom'
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

const Header = () => {
    return (
        <div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-3 bg-secondary d-none d-lg-block">
                        <Link to="/" className="navbar-brand w-100 h-100 m-0 p-0 d-flex align-items-center justify-content-center">
                            <h1 className="m-0 display-4 text-primary text-uppercase">Om Nagar</h1>
                        </Link>
                    </div>
                    <div className="col-lg-9">
                        <div className="row bg-white border-bottom d-none d-lg-flex">
                            <div className="col-lg-7 text-left">
                                <div className="h-100 d-inline-flex align-items-center py-2 px-3">
                                    <i className="fa fa-envelope text-primary mr-2"></i>
                                    <small>info@example.com</small>
                                </div>
                                <div className="h-100 d-inline-flex align-items-center py-2 px-2">
                                    <a href="https://wa.me/9312126754" target="_blank"><i className="fa fa-phone-alt text-primary mr-2"></i>
                                        <small>+91 931 2126 754</small></a>
                                </div>
                            </div>
                            <div className="col-lg-5 text-right">
                                <div className="d-inline-flex align-items-center p-2">
                                    <a className="btn btn-sm btn-outline-primary btn-sm-square mr-2" href="">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                    <a className="btn btn-sm btn-outline-primary btn-sm-square mr-2" href="">
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                    <a className="btn btn-sm btn-outline-primary btn-sm-square mr-2" href="">
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                    <a className="btn btn-sm btn-outline-primary btn-sm-square mr-2" href="">
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                    <a className="btn btn-sm btn-outline-primary btn-sm-square mr-2" href="">
                                        <i className="fab fa-youtube"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <Navbar expand="lg" bg="white" variant="light" className="border-bottom">
                            <div className="container">
                                <Navbar.Brand as={Link} to="/" className="d-block d-lg-none">
                                    <h1 className="m-0 display-4 text-primary text-uppercase">Om Nagar</h1>
                                </Navbar.Brand>
                                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="mr-auto">
                                        <Nav.Link as={Link} to="/" className="nav-link">Home</Nav.Link>
                                        <Nav.Link as={Link} to="/about" className="nav-link">About</Nav.Link>
                                        <Nav.Link as={Link} to="/service" className="nav-link">Practice</Nav.Link>
                                        <Nav.Link as={Link} to="/contact" className="nav-link">Contact</Nav.Link>
                                    </Nav>
                                </Navbar.Collapse>
                            </div>
                        </Navbar>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header
