import React from 'react'
import Header from '../Header'
import Footer from '../Footer'

const Service = () => {
    return (
        <>
            <Header />
            {/* <!-- Services Start --> */}
            <div className="container-fluid py-5">
                <div className="container py-5">
                    <div className="row">
                        <div className="col-lg-3">
                            <h6 className="text-uppercase">Practice Areas</h6>
                            <h1 className="mb-4">Our Practice Areas</h1>
                            <p> We specialize in a comprehensive range of practice areas to meet the diverse legal needs of our clients. From criminal defense and civil litigation to family law and business matters, we provide adept legal counsel and representation. In criminal cases, we diligently defend clients rights, ensuring fair treatment and striving for favorable outcomes. Civil litigation is navigated with precision, seeking resolution and justice for our clients. In family law, we offer compassionate support and expert guidance through delicate matters like divorce, custody, and adoption. Additionally, our expertise in business law ensures that companies receive sound legal advice to navigate complex regulations, contracts, and disputes effectively.</p>
                           
                        </div>
                        <div className="col-lg-9 pt-5 pt-lg-0">
                            <div className="bg-primary rounded" style={{ height: '200px' }}></div>
                            <div className="owl-carousel service-carousel position-relative" style={{ marginTop: '-100px', padding: '0 30px' }}>
                                <div className="d-flex flex-column align-items-center text-center bg-white rounded pt-4">
                                    <div className="icon-box bg-secondary text-primary mt-2 mb-4">
                                        <i className="fa fa-2x fa-gavel"></i>
                                    </div>
                                    <h5 className="mb-4 px-4">Criminal Law</h5>
                                    <p className="m-0">Expert defense against charges, ensuring fair trials and protecting clients rights and freedoms with diligence.</p>
                                </div>
                                <div className="d-flex flex-column align-items-center text-center bg-white rounded pt-4">
                                    <div className="icon-box bg-secondary text-primary mt-2 mb-4">
                                        <i className="fa fa-2x fa-landmark"></i>
                                    </div>
                                    <h5 className="mb-4 px-4">Civil Law</h5>
                                    <p className="m-0">Resolving disputes and upholding rights through legal avenues, fostering justice and harmony in society.</p>
                                </div>
                                <div className="d-flex flex-column align-items-center text-center bg-white rounded pt-4">
                                    <div className="icon-box bg-secondary text-primary mt-2 mb-4">
                                        <i className="fa fa-2x fa-users"></i>
                                    </div>
                                    <h5 className="mb-4 px-4">Family Law</h5>
                                    <p className="m-0">Compassionate guidance in matters of family dynamics, including divorce, child custody, and adoption proceedings.</p>
                                </div>
                                <div className="d-flex flex-column align-items-center text-center bg-white rounded pt-4">
                                    <div className="icon-box bg-secondary text-primary mt-2 mb-4">
                                        <i className="fa fa-2x fa-hand-holding-usd"></i>
                                    </div>
                                    <h5 className="mb-4 px-4">Business Law</h5>
                                    <p className="m-0">Safeguarding businesses with legal expertise, navigating regulations, contracts, and disputes to ensure success and compliance.</p>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Services End --> */}
            <Footer />
        </>
    )
}

export default Service
